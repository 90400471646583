<template>
    <div>
        <table class="table table-bordered table-striped" v-bind:class="{ 'table-dark': darkMode }">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col" v-if="cues[0].startTime != null">Start</th>
                    <th scope="col">Text</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="cue in cues" v-bind:key="cue.id">
                    <th scope="row" v-text="cue.id"></th>
                    <td v-if="cue.startTime != null" v-text="cue.startTime"></td>
                    <td v-html="cue.text"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  name: 'ParsedInput',
   props: {
    cues: Array,
    darkMode: Boolean
  }
}
</script>
