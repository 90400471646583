<template>
  <div id="app">
    <div>
      <!-- As a heading -->
      <b-navbar variant="info" type="dark" class="bg-dark">
        <b-navbar-brand tag="h1" class="mb-0"><img src="./ladbsmall.png" class="d-inline-block align-top"> Censor Tool v2</b-navbar-brand>
        <b-navbar-nav class="ml-auto text-light">
          <b-form-checkbox v-model="darkMode" switch>Style {{ darkMode ? "(dark)" : "(light)"}}</b-form-checkbox>
        </b-navbar-nav>
      </b-navbar>
    </div>
    <br>
    <div class="container">
      <div class="row">
        <div class="col">
          <b-card title="Caption File" v-bind:class="{ 'bg-dark': darkMode, 'text-light': darkMode }">
            <CueListLoader @load="text = $event.text; cues = $event.cues; flaggedCues = matchCues(cues, wordList)" /></b-card>
        </div>
      </div>
      <br>
      <div v-if="wordList.length<1 || wordListName == ''" class="row">
        <div class="col">
          <b-card title="Please choose a word list" class="text-danger bg-light" v-bind:class="{ 'bg-dark': darkMode }">
            No word list has been selected. You can choose a word list in the Word List tab.</b-card>
        </div>
      </div>
      <br v-if="wordList.length<1 || wordListName == ''">
      <div>
      <b-card no-body v-bind:class="{ 'bg-dark': darkMode, 'text-light': darkMode }">
        <b-tabs pills card>
          <b-tab v-bind:title="'Flagged Cues (' + flaggedCues.length + ')'" active><b-card-text><FlaggedCues v-bind:cues="flaggedCues" v-bind:darkMode="darkMode" /></b-card-text></b-tab>
          <b-tab v-bind:title="'Parsed Input (' + cues.length + ')'"><b-card-text><ParsedInput v-bind:cues="cues" v-bind:darkMode="darkMode" /></b-card-text></b-tab>
          <b-tab title="Raw Input"><b-card-text><RawInput v-bind:input="text" v-bind:darkMode="darkMode" /></b-card-text></b-tab>
          <b-tab v-bind:title="'Word List (' + (wordListName ? wordListName + ', ' + wordList.length : 'not set') + ')'">
            <b-card-text>
              <div class="row">
                <div class="col">
                  <WordList v-bind:input="wordList" v-bind:darkMode="darkMode" :selectedList="wordListName" @load="wordList = $event.list; saveWords(wordList); wordListName = $event.name; flaggedCues = matchCues(cues, wordList)" />
                </div>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</div>
</template>

<script>
import CueListLoader from './components/CueListLoader.vue'
import ParsedInput from './components/ParsedInput.vue'
import FlaggedCues from './components/FlaggedCues.vue'
import RawInput from './components/RawInput.vue'
import WordList from './components/WordList.vue'


export default {
  name: 'App',
  mounted() {
    if (localStorage.wordList) {
      this.wordList = JSON.parse(localStorage.getItem("wordList"));
    }
    if (localStorage.wordListName) {
      this.wordListName = localStorage.getItem("wordListName");
    }
    if (localStorage.darkMode) {
      this.darkMode = (localStorage.getItem("darkMode") == "true");
    }
  },
  data: () => ({
    darkMode: false,
    text: "",
    wordList: [],
    wordListName: "",
    cues: [],
    flaggedCues: []
    }),
  components: {
    CueListLoader,
    ParsedInput,
    FlaggedCues,
    RawInput,
    WordList
  },
  methods: {
    saveWords: (words) => {
      localStorage.setItem("wordList", JSON.stringify(words));
    },
    matchCues: (cues, wordList) => { 
      var matchedCues = [];
      cues.forEach((cue) => {
        var activeCue = Object.assign({}, cue)
        activeCue.isExactMatch = false
        activeCue.exactMatchText = String(activeCue.text)
        var isMatch = false;
        // First pass for exact match list
        wordList.forEach((word) => {
          if (activeCue.text.match(new RegExp("\\b"+word+"\\b", "gi"))) {
            if (!activeCue.exactMatch) {
              activeCue.exactMatch = [];
            }
            activeCue.exactMatchText = activeCue.exactMatchText.replace(new RegExp("\\b"+word+"\\b", "gi"), (match) => `<mark>${match}</mark>`);
            activeCue.exactMatch.push(word);
            activeCue.isExactMatch = true;
          }
        });
        // Second pass for full match list
        wordList.forEach((word) => {
          if (activeCue.text.match(new RegExp(word, "gi"))) {
            if (!activeCue.match) {
              activeCue.match = [];
            }
            activeCue.text = activeCue.text.replace(new RegExp(word, "gi"), (match) => `<mark>${match}</mark>`);
            activeCue.match.push(word);
            isMatch = true;
          }
        });
        if (isMatch) {
            matchedCues.push(activeCue);
          }
      });
      return matchedCues;
    }
  },
  watch: {
    darkMode(mode) {
      localStorage.setItem('darkMode', mode)
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #6c757d
}

html {
  background-color: #6c757d
}

mark {
  color: #343a40 !important;
  background-color: #f0dc2f !important;
}
</style>
