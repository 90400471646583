function pi() {
    this.parseItt = function(data) {
        let captions = [];
        let cur_cap = { "id": "", "startTime": "", "endTime": "", "text": "" };
        let regexCaption = /<p.*?begin="(?<startTime>([0-9-]{2}[:;]){3}[0-9-]{2})" end="(?<endTime>([0-9-]{2}[:;]){3}[0-9-]{2})".*?>(?<content>.*)<\/p>/gm;
        data.split('\n').forEach((line) => {
            let startvalues = regexCaption.exec(line)
            if (startvalues) {
                if (cur_cap.text.length && cur_cap.text.length > 0) {
                    cur_cap.text = cur_cap.text.trim();
                    captions.push(JSON.parse(JSON.stringify(cur_cap)));
                }
                captions.push({
                    "id": (captions.length + 1),
                    "startTime": startvalues.groups.startTime,
                    "endTime": startvalues.groups.endTime,
                    "text": startvalues.groups.content
                })
                //captions.push(cur_cap)
        //    } else {
        //        if (line != "" && line != "<end subtitles>" && cur_cap.startTime != "") {
        //            cur_cap.text += (cur_cap.text.length > 0 ? " " : "") + line;
        //        }
            }
        });
        //if (cur_cap.startTime) {
        //    cur_cap.text = cur_cap.text
        //        .trim()
        //        .replace("\r", "");
        //    captions.push(cur_cap);
        //}
        return captions.length > 0 ? captions : false;
    }
}

module.exports = pi;