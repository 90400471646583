function SCCParser() {
    this.parseSCC = function(data) {
        var captions = [];
        var cur_cap = { "id": "", "startTime": "", "endTime": "", "text": "" };
        let regexCaption = /(?<startTime>([0-9-]{2}[:;]){3}[0-9-]{2})(?<payload>([\t ][0-9a-fA-F]{4}){0,})/g;
        data.split('\n').forEach((line) => {
            let oldrow = 0
            let startvalues = regexCaption.exec(line)
            if (startvalues) {
                startvalues
                    .groups
                    .payload
                    .replace('\t','')
                    .trim()
                    .split(' ')
                    .forEach(
                        (item) => {
                            if (item[0] == '9' || item[0] == '1') {
                                let row = 0
                                if (['1','9'].includes(item[1])) {
                                    if (['4','5','c','d'].includes(item[2])) {
                                        row = 1
                                    } else if (['6','7','e','f'].includes(item[2])) {
                                        row = 2
                                    }
                                } else if (['2','a'].includes(item[1])) {
                                    if (['4','5','c','d'].includes(item[2])) {
                                        row = 3
                                    } else if (['6','7','e','f'].includes(item[2])) {
                                        row = 4
                                    }
                                } else if (['5','d'].includes(item[1])) {
                                    if (['4','5','c','d'].includes(item[2])) {
                                        row = 5
                                    } else if (['6','7','e','f'].includes(item[2])) {
                                        row = 6
                                    }
                                } else if (['6','e'].includes(item[1])) {
                                    if (['4','5','c','d'].includes(item[2])) {
                                        row = 7
                                    } else if (['6','7','e','f'].includes(item[2])) {
                                        row = 8
                                    }
                                } else if (['7','f'].includes(item[1])) {
                                    if (['4','5','c','d'].includes(item[2])) {
                                        row = 9
                                    } else if (['6','7','e','f'].includes(item[2])) {
                                        row = 10
                                    }
                                } else if (['0','8'].includes(item[1])) {
                                    if (['4','5','c','d'].includes(item[2])) {
                                        row = 11
                                    }
                                } else if (['3','b'].includes(item[1])) {
                                    if (['4','5','c','d'].includes(item[2])) {
                                        row = 12
                                    } else if (['6','7','e','f'].includes(item[2])) {
                                        row = 13
                                    }
                                } else if (['4','c'].includes(item[1])) {
                                    if (['4','5','c','d'].includes(item[2])) {
                                        row = 14
                                    } else if (['6','7','e','f'].includes(item[2])) {
                                        row = 15
                                    }
                                }
                                if (row) {
                                    if (oldrow && row && oldrow != row) {
                                        cur_cap.text += "<br>"
                                    }
                                    oldrow = row
                                }
                            } else {
                                var char1 = parseInt(`0x${item[0]}${item[1]}`)
                                if (char1 >= 0x80) {
                                    char1 -= 0x80
                                }
                                var char2 = parseInt(`0x${item[2]}${item[3]}`)
                                if (char2 >= 0x80) {
                                    char2 -= 0x80
                                }
                                cur_cap.text += String.fromCharCode(char1) + String.fromCharCode(char2)
                            }
                    });
                if (cur_cap.text) {
                    cur_cap.text = cur_cap.text
                        .trim()
                        .replace("\r", "")
                        .replace("\u0000", " ");
                    cur_cap.id = (captions.length + 1);
                    cur_cap.startTime = startvalues.groups.startTime;
                    captions.push(JSON.parse(JSON.stringify(cur_cap)));
                }
                Object.assign(cur_cap, {
                    "id": "",
                    "startTime": "",
                    "endTime": "",
                    "text": ""
                })
            }
        });

        captions.forEach((cap) => {
            cap.text = cap.text.replace(/<(?![/]{0,1}(br|span)[^<>]*?>)/g, "&lt;").replace(/(?<![/]{0,1}(br|span)[^<>]*?)>/g, "&gt;")
        })
        
        if (cur_cap.startTime) {
            cur_cap.text = cur_cap.text
                .trim()
                .replace("\r", "");
            captions.push(cur_cap);
        }
        return captions.length > 0 ? captions : false;
    }
}

module.exports = SCCParser;