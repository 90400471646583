function pt() {
    this.parseTranscript = function(data) {
        let captions = [];
        data.split('\n').forEach((line, idx) => {
            captions.push({"id": (idx + 1), "text": line})
        });
        return captions.length > 0 ? captions : false;
    }
}

module.exports = pt;