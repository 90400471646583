<template>
  <div>
    <b-form-file
      placeholder="Supported formats: .srt/.vtt, .txt, .scc, .itt - Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      v-on:change="loadTextFromFile"
      accept=".srt, .vtt, .txt, .scc" />
  </div>
</template>

<script>
import srtParser2 from 'srt-parser-2'
var srtParser = new srtParser2()
var CheetahParser = require('../parsers/parsecheetah');
var cheetahParser = new CheetahParser();
var MaccapParser = require('../parsers/parsemaccap');
var maccapParser = new MaccapParser();
var NitrisParser = require('../parsers/parsenitris');
var nitrisParser = new NitrisParser();
var TranscriptParser = require('../parsers/parsetranscript');
var transcriptParser = new TranscriptParser();
var SccParser = require('../parsers/parsescc');
var sccParser = new SccParser();
var IttParser = require('../parsers/parseitt');
var ittParser = new IttParser();

export default {
  methods: {
    parseSubs: (rawSubs, type) => {
      switch(type) {
        case "srt":
          console.log('parsing as srt')
          return srtParser.fromSrt(rawSubs);
        case "txt":
          console.log('parsing as txt')
            return maccapParser.parsemaccap(rawSubs) || cheetahParser.parsecheetah(rawSubs) || nitrisParser.parseNitris(rawSubs) || transcriptParser.parseTranscript(rawSubs);
        case "scc":
          console.log('parsing as scc')
          return sccParser.parseSCC(rawSubs);
        case "itt":
          console.log('parsing as itt')
          return ittParser.parseItt(rawSubs)
      }
      
      },
    loadTextFromFile(ev) {
      var file
      var type
      if (ev.dataTransfer) { // we get a dataTransfer instead of a target in a drop event
        if (ev.dataTransfer.files[0].name.match(".srt|.vtt")) {
          file = ev.dataTransfer.files[0]
          type = "srt"
        } else if (ev.dataTransfer.files[0].name.match('.txt')) {
          file = ev.dataTransfer.files[0]
          type = "txt"
        } else if (ev.dataTransfer.files[0].name.match('.scc')) {
          file = ev.dataTransfer.files[0]
          type = "scc"
        } else if (ev.dataTransfer.files[0].name.match('.itt')) {
          file = ev.dataTransfer.files[0]
          type = "itt"
        } else {
          console.log("not a supported caption file")
          return;
        }

      } else {
        file = ev.target.files[0]
        if (ev.target.files[0].name.match('.srt|.vtt')) {
          type = "srt"
        } else if (ev.target.files[0].name.match('.txt')) {
          type = "txt"
        } else if (ev.target.files[0].name.match('.scc')) {
          type = "scc"
        } else if (ev.target.files[0].name.match('.itt')) {
          type = "itt"
        }
      }
      const reader = new FileReader();
      reader.onload = e => this.$emit("load", {"text": e.target.result, "cues": this.parseSubs(e.target.result, type)});
      reader.readAsText(file);
    }
  }
};
</script>
