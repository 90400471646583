<template>
    <div>
        <pre v-text="input" v-bind:class="{ 'text-light': darkMode }"></pre>
    </div>
</template>

<script>
export default {
  name: 'RawInput',
   props: {
    darkMode: Boolean,
    input: String
  }
}
</script>
