<template>
  <div class="row">
    <div class="col">
      <b-form-textarea v-model="words" rows="25" v-bind:readonly="(selectedList != 'extended') || !modifiable"/>
    </div>
    <div class="col-md-auto">
      <div>
        <b-card title="Choose Word List" v-bind:class="{ 'bg-dark': darkMode }">
          <b-form-group
            label="Word list to censor against"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              size="lg"
              name="radio-btn-outline"
              buttons
            ></b-form-radio-group>
          </b-form-group>
          <b-button
            v-if="this.modifiable && (JSON.stringify(this.input) != JSON.stringify(this.wordlists.extended)) && this.input.length > 100"
            variant="danger"
            v-on:click="saveWords()"
          >Publish Changes</b-button>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WordList',
   props: {
    darkMode: Boolean,
    input: Array,
    selectedList: String
  },
  mounted() {
    Promise.all([
      fetch('/wordlists/mutable')
        .then((e) => {
          if (e.status == 200) {
            this.modifiable = true
          } else {
            this.modifiable = false
          }
        })
        .catch(() => {
          this.modifiable = false;
        }),
      fetch('/wordlists/basic.json')
        .then(payload => payload.json())
        .then(payload => {this.wordlists.basic = payload;}),
      fetch('/wordlists/extended.json')
        .then(payload => payload.json())
        .then(payload => {this.wordlists.extended = payload;})
    ]).then(() => {
      if (localStorage.wordListName) {
        switch (localStorage.getItem("wordListName")) {
          case 'basic':
            this.$emit("load", { "list": this.wordlists.basic, "name": "basic"})
            break
          case 'extended':
            this.$emit("load", { "list": this.wordlists.extended, "name": "extended"})
            break;
        }
      }
    })
  },
  methods: {
    saveWords() {
      fetch('/wordlists/extended.json', {
        method: 'PUT',
        body: JSON.stringify(
          [...new Set( // removes duplicates
          this.input
            .filter(n=>n) // empty lines
            .map(n=>n.trim()) // extra whitespace
            .sort(function (a, b) { // We need to pass a custom sort function to sort case-insensitively
              if ( a.toLowerCase() < b.toLowerCase() ) {
                return -1;
              } else if ( a.toLowerCase() > b.toLowerCase() ) {
                return 1;
              } else {
                return 0;
              }
            }
            )
          )]
        )
      })
      .then(
        setTimeout(() => {
          fetch('/wordlists/extended.json')
            .then(payload => payload.json())
            .then(payload => {this.wordlists.extended = payload;})
            .catch()
          }, 100))
      .then(() => {
        if (localStorage.wordListName) {
          switch (localStorage.getItem("wordListName")) {
            case 'basic':
              this.$emit("load", { "list": this.wordlists.basic, "name": "basic"})
              break
            case 'extended':
              this.$emit("load", { "list": this.wordlists.extended, "name": "extended"})
              break;
          }
        }
      })
      .catch()
    }
  },
  data: () => ({
    options: [
      { text: 'Basic', value: 'basic' },
      { text: 'Extended', value: 'extended' }
    ],
    modified: false,
    modifiable: false,
    wordlists: {
      basic: [],
      extended: []
    }
  }),
  computed: {
    selected: {
      get: function() { return this.selectedList },
      set: function(e) {
        switch(e) {
          case "basic":
            this.$emit("load", { "list": this.wordlists.basic, "name": "basic"})
            localStorage.setItem("wordListName", "basic")
            break;
          case "extended":
            this.$emit("load", { "list": this.wordlists.extended, "name": "extended"})
            localStorage.setItem("wordListName", "extended")
            break;
        default:
        }
      }
    },
    words: {
      get: function() { return this.input.join("\n"); },
      set: function(e) {
        if (this.selected === "extended") {
          this.$emit("load", { "list": e.split("\n"), "name": this.selected});
          this.modified = true;
        }
      }
    }
  }
}
</script>