function pc() {
    this.parsecheetah = function(data) {
        let captions = [];
        let cur_cap = { "id": "", "startTime": "", "endTime": "", "text": "" };
        let regexCaption = /(?<id>\d+) (?<startTime>([0-9-]{2}[:;]){3}[0-9-]{2}) (?<endTime>([0-9-]{2}[:;]){3}[0-9-]{2})/g;
        data.split('\n').forEach((line) => {
            let startvalues = regexCaption.exec(line)
            if (startvalues) {
                if (cur_cap.text.length && cur_cap.text.length > 0) {
                    cur_cap.text = cur_cap.text.trim();
                    cur_cap.text = cur_cap.text.replace("\r", "")
                    captions.push(JSON.parse(JSON.stringify(cur_cap)));
                }
                Object.assign(cur_cap, {
                    "id": startvalues.groups.id,
                    "startTime": startvalues.groups.startTime,
                    "endTime": startvalues.groups.endTime,
                    "text": ""
                })
            } else {
                if (line.trim() != "") {
                    cur_cap.text += (cur_cap.text.length > 0 ? "<br/>" : "") + line;
                }
            }
        });
        if (cur_cap.startTime) {
            cur_cap.text = cur_cap.text
                .trim()
                .replace("\r", "");
            captions.push(cur_cap)
        }

        captions.forEach((cap) => {
            cap.text = cap.text.replace(/<(?![/]{0,1}(br|span)[^<>]*?>)/g, "&lt;").replace(/(?<![/]{0,1}(br|span)[^<>]*?)>/g, "&gt;")
        })

        return captions.length > 0 ? captions : false;
    }
}

module.exports = pc;