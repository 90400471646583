function pm() {
    this.parsemaccap = function(data) {
        var captions = [];
        var cur_cap = { "id": "", "startTime": "", "endTime": "", "text": "" };
        let regexStart = /\*T (?<startTime>([0-9-]{2}[:;]){3}[0-9-]{2})/g;
        let regexEnd = /\*E (?<startTime>([0-9-]{2}[:;]){3}[0-9-]{2})/g
        data.split('\n').forEach((line) => {
            let matchStart = regexStart.exec(line);
            let matchEnd = regexEnd.exec(line);

            if (matchStart) {
                if (cur_cap.text.length && cur_cap.text.length > 0) {
                    cur_cap.text = cur_cap.text.trim();
                    captions.push(JSON.parse(JSON.stringify(cur_cap)));
                }
                Object.assign(cur_cap, {
                    "id": (captions.length + 1),
                    "startTime": matchStart.groups.startTime,
                    "endTime": "",
                    "text": ""
                })
            } else if (matchEnd) {
                cur_cap.endTime = matchEnd.groups.endTime;
            } else {
                if (line.trim() != "") {
                    cur_cap.text += (cur_cap.text.length > 0 ? "<br/>" : "") + line;
                }
            }
        });
        if (cur_cap.startTime) {
            cur_cap.text = cur_cap.text
                .trim()
                .replace("\r", "");
            captions.push(cur_cap)
        }

        captions.forEach((cap) => {
            cap.text = cap.text.replace(/<(?![/]{0,1}(br|span)[^<>]*?>)/g, "&lt;").replace(/(?<![/]{0,1}(br|span)[^<>]*?)>/g, "&gt;")
        })

        return captions.length > 0 ? captions : false;
    }
}

module.exports = pm;