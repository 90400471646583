<template>
    <div>
        <b-check id="showexact" v-model="showexact" size="lg" switch>Exact word matches only<span v-if="showexact" class="text-danger d-print-none"> (Warning, some matches will not be shown)</span><span v-if="!showexact" class="text-primary d-print-none"> (All matches will be shown)</span></b-check>
        <br>
        <table v-if="cues.length > 0" class="table table-bordered table-striped" v-bind:class="{ 'table-dark': darkMode }">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col" v-if="cues[0].startTime != null">Start</th>
                    <th scope="col">Match</th>
                    <th scope="col">Text</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="cue in cues.filter(cue => cue.isExactMatch||!showexact)" v-bind:key="cue.id">
                    <th scope="row" v-text="cue.id"></th>
                    <td v-if="cue.startTime != null" v-text="cue.startTime"></td>
                    <td v-html="cue.match.join('<br>')" v-if="!showexact"></td>
                    <td v-html="cue.exactMatch.join('<br>')" v-if="showexact"></td>
                    <td v-html="cue.text" v-if="!showexact"></td>
                    <td v-html="cue.exactMatchText" v-if="showexact"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  name: 'FlaggedCues',
   data() {
     return {
       showexact: false
     }
   },
   props: {
    cues: Array,
    darkMode: Boolean
  }
}
</script>
